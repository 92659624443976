export default [
  "Dayton",
  "Indiana State",
  "Rice",
  "Fisk",
  "Southeastern",
  "Little Rock",
  "Penn State",
  "Wyoming",
  "Clemson",
  "Texas Southern",
  "Regent",
  "Providence",
  "Sacred Heart",
  "Albion",
  "Detroit",
  "Maine-Machias",
  "West Virginia",
  "Holy Names",
  "Nevada",
  "Fordham",
  "Oklahoma",
  "Dartmouth",
  "Cleveland State",
  "Saint Leo",
  "Stetson",
  "Hiwassee",
  "SIU-Edwardsville",
  "Gardner-Webb",
  "North Carolina A&T",
  "Lamar",
  "Ohio State",
  "Central Arkansas",
  "California Baptist",
  "Liberty",
  "UC-Davis",
  "Auburn",
  "North Carolina Central",
  "Maine-Fort Kent",
  "Western State",
  "Rust",
  "UMass-Lowell",
  "Loyola (IL)",
  "Northwestern State",
  "Kennesaw State",
  "Northwestern Ohio",
  "Fairleigh Dickinson",
  "Hawaii-Hilo",
  "Marist",
  "Northern Iowa",
  "Saint Mary's",
  "Delaware",
  "Houston",
  "Florida International",
  "UCSB",
  "Southern Illinois",
  "Hampton",
  "Arizona State",
  "Truett-McConnell",
  "Utah",
  "Penn",
  "Morehead State",
  "Kansas",
  "St. Joseph's (NY)",
  "Peace",
  "UTEP",
  "UNLV",
  "LIU-Brooklyn",
  "Texas A&M",
  "Michigan State",
  "Drake",
  "UT-Martin",
  "Nebraska",
  "Louisiana College",
  "Seattle",
  "Tennessee Tech",
  "Oakland",
  "Penn St.-Wilkes Barre",
  "Army",
  "Brevard",
  "Georgia College",
  "USC Upstate",
  "Syracuse",
  "Wagner",
  "Cal Tech",
  "Boston University",
  "Boston College",
  "Furman",
  "Air Force",
  "Kent State",
  "California",
  "Mississippi State",
  "Washington State",
  "College of Idaho",
  "Warren Wilson",
  "Trinity Christian",
  "Truman State",
  "Lipscomb",
  "Morgan State",
  "Central State (OH)",
  "Alabama A&M",
  "Williams Baptist",
  "Virginia Tech",
  "Washington",
  "Hofstra",
  "Marshall",
  "Green Bay",
  "Buffalo",
  "Iowa State",
  "UC-Merced",
  "New Hampshire",
  "William & Mary",
  "Central Connecticut",
  "Pacific",
  "Louisiana Tech",
  "Indiana-Northwest",
  "Texas-Rio Grande Valley",
  "Reinhardt",
  "Seton Hall",
  "Delaware State",
  "Oklahoma State",
  "Corban",
  "Northern Illinois",
  "Drexel",
  "Georgetown",
  "Oral Roberts",
  "San Diego",
  "Hawaii",
  "Bethany College (KS)",
  "Alabama-Birmingham",
  "Quinnipiac",
  "Saint Louis",
  "Ohio",
  "St. Francis (NY)",
  "St. Peter's",
  "Coppin State",
  "Iona",
  "Tennessee State",
  "Abilene Christian",
  "Canisius",
  "La Salle",
  "Western Michigan",
  "Northern Arizona",
  "Nicholls State",
  "Bob Jones University",
  "Miami (OH)",
  "Santa Clara",
  "NC State",
  "South Carolina State",
  "Xavier",
  "Grand Canyon",
  "Benedictine (IL)",
  "Texas-Arlington",
  "Georgia Tech",
  "UConn",
  "St. Bonaventure",
  "Loyola Marymount",
  "Jackson State",
  "Colorado",
  "Central Pennsylvania College",
  "Valparaiso",
  "Virginia",
  "Yale",
  "Portland State",
  "SMU",
  "UC-Riverside",
  "UNC Wilmington",
  "Miles",
  "Fairfield",
  "Bethune-Cookman",
  "UNC Asheville",
  "Franciscan",
  "Alcorn State",
  "Georgia State",
  "Citadel",
  "Texas",
  "Stony Brook",
  "Fontbonne",
  "Lafayette",
  "Rider",
  "Hartford",
  "Ole Miss",
  "Missouri",
  "Cal State Bakersfield",
  "New Mexico State",
  "Baylor",
  "Harvard",
  "Purdue-Fort Wayne",
  "Youngstown State",
  "Austin Peay",
  "Towson",
  "Western Illinois",
  "Maryland-Eastern Shore",
  "Radford",
  "Texas Tech",
  "Kentucky Christian",
  "Loyola (LA)",
  "Harris-Stowe",
  "Central Washington",
  "New Rochelle",
  "NJIT",
  "Southeast Missouri State",
  "Southern Utah",
  "Miami (FL)",
  "Creighton",
  "Duquesne",
  "Trinity Baptist",
  "UNC",
  "Pepperdine",
  "Florida State",
  "Toledo",
  "Southeastern Louisiana",
  "Cal State Northridge",
  "Howard Payne",
  "St. Edward's",
  "South Alabama",
  "Marquette",
  "Huntingdon",
  "Idaho State",
  "Robert Morris",
  "Rutgers",
  "Duke",
  "Maine",
  "George Mason",
  "Vanderbilt",
  "Albany (NY)",
  "Purdue",
  "Arkansas-Pine Bluff",
  "South Florida",
  "Incarnate Word",
  "Niagara",
  "New Orleans",
  "Millsaps",
  "Illinois State",
  "Omaha",
  "Florida Atlantic",
  "Mercer",
  "Dubuque",
  "Calvary",
  "Memphis",
  "Sacramento State",
  "LSU",
  "ETSU",
  "BYU",
  "Wilmington (DE)",
  "Sonoma State",
  "Florida A&M",
  "Sam Houston State",
  "George Washington",
  "Middle Tennessee",
  "South Carolina",
  "Northwestern",
  "Florida",
  "Eastern Washington",
  "IUPUI",
  "UMass",
  "Portland",
  "Arkansas State",
  "Mississippi Valley State",
  "Cal Lutheran",
  "Loyola (MD)",
  "North Alabama",
  "Iowa",
  "Le Moyne",
  "Rhode Island",
  "Villanova",
  "Kansas State",
  "Notre Dame",
  "Fort Valley State",
  "Voorhees",
  "Saint Francis (PA)",
  "Holy Cross",
  "Tulane",
  "Coe",
  "Tennessee",
  "Stephen F. Austin",
  "Florida College",
  "Western Kentucky",
  "Columbia",
  "Wofford",
  "Montana State",
  "Carver Bible",
  "Charlotte",
  "Wichita State",
  "Maryland",
  "Samford",
  "DePaul",
  "UCF",
  "Kentucky",
  "Boise State",
  "Houston Baptist",
  "Arizona",
  "Vermont",
  "Richmond",
  "Wisconsin-Stout",
  "Hartwick",
  "Cairn",
  "Eastern Illinois",
  "Texas A&M-Corpus Christi",
  "Schreiner",
  "High Point",
  "College of Charleston",
  "Campbell",
  "Tulsa",
  "Hood",
  "Belmont",
  "Greensboro",
  "Prairie View",
  "Indiana-Kokomo",
  "USC",
  "North Florida",
  "Cornell",
  "Brewton-Parker",
  "Colgate",
  "Montana",
  "Weber State",
  "North Carolina Wesleyan",
  "Texas State",
  "Concordia (TX)",
  "Covenant",
  "Utah State",
  "Huston-Tillotson",
  "Champion Baptist",
  "UIC",
  "UCLA",
  "Winthrop",
  "St. Mary's (MD)",
  "Bellevue",
  "Florida Gulf Coast",
  "Colorado State",
  "Lehigh",
  "Cincinnati",
  "TCU",
  "McNeese State",
  "San Jose State",
  "Trinity (TX)",
  "Stanford",
  "Minnesota",
  "Georgia Southern",
  "VCU",
  "Cal St Dominguez Hills",
  "Wilberforce",
  "Jacksonville",
  "Bowling Green State",
  "Centenary (LA)",
  "Norfolk State",
  "UNC Greensboro",
  "Alabama",
  "Chicago State",
  "Bucknell",
  "Denver",
  "Rosemont",
  "Johnson & Wales (NC)",
  "Troy",
  "San Diego State",
  "New Mexico",
  "Bradley",
  "Mayville State",
  "North Texas",
  "Arlington Baptist",
  "Eastern Michigan",
  "Northern Colorado",
  "South Dakota",
  "UC-Irvine",
  "James Madison",
  "Portland Bible College",
  "Charleston Southern",
  "Florida Memorial",
  "Ball State",
  "Savannah State",
  "Cal Poly",
  "Murray State",
  "Navy",
  "Northland",
  "UMBC",
  "Mobile",
  "Wright State",
  "Johnson & Wales",
  "Mount St. Mary's",
  "Evansville",
  "Illinois",
  "Chattanooga",
  "Grambling",
  "William Jessup",
  "Louisiana",
  "Bryant",
  "Howard",
  "Cal State Fullerton",
  "Presbyterian",
  "Northeastern",
  "East Carolina",
  "Milwaukee",
  "Fresno State",
  "Idaho",
  "Life Pacific",
  "Georgia",
  "Oregon State",
  "Butler",
  "Missouri State",
  "Oregon",
  "St. Francis (IL)",
  "Manhattan",
  "Louisville",
  "Mid-America Christian",
  "Longwood",
  "Princeton",
  "Central Michigan",
  "Brown",
  "UTSA",
  "Davidson",
  "Westcliff",
  "UMKC",
  "Alabama State",
  "Monmouth",
  "Milligan",
  "Notre Dame (CA)",
  "North Dakota",
  "Siena Heights",
  "Northern Kentucky",
  "Louisiana-Monroe",
  "Wisconsin",
  "San Francisco",
  "Utah Valley",
  "Indiana",
  "Western Carolina",
  "St. John's (NY)",
  "Michigan",
  "Gonzaga",
  "Temple",
  "Elon",
  "Arkansas",
  "Siena",
  "American",
  "Wake Forest",
  "St. Joseph's",
  "Jacksonville State",
  "South Dakota State",
  "North Dakota State",
  "Long Beach State",
  "Wisconsin Lutheran",
  "Averett",
  "Middle Georgia State",
  "Eastern Kentucky",
  "Coastal Carolina",
  "Binghamton",
  "Southern Miss",
  "VMI",
  "Southern",
  "Pitt",
  "Frostburg State",
  "Old Dominion",
  "Akron",
  "Appalachian State"
];
